<template>
  <component
    :is="icon"
    :size="size"
    :color="color"
    :stroke-width="strokeWidth"
  />
</template>

<script>
import { similarityScore, snakeCaseToCamel } from "@/utils/string";
import * as icons from "lucide-vue-next";
const iconNames = Object.keys(icons);

export default {
  name: "LuicideIcon",
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 20,
    },
    color: {
      type: String,
      default: "currentColor",
    },
    strokeWidth: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    icon() {
      const capitalName = snakeCaseToCamel(this.name);
      if (icons[capitalName]) return icons[capitalName];
      const name = findClosestIcon(capitalName, iconNames);
      return icons[name];
    },
  },
};

function findClosestIcon(input, iconNames) {
  const threshold = 0.5; // Adjust similarity threshold as needed
  const matches = iconNames
    .map((icon) => ({
      icon,
      similarity: similarityScore(input, icon),
    }))
    .filter((result) => result.similarity >= threshold)
    .sort((a, b) => b.similarity - a.similarity);

  return matches[0]?.icon;
}
</script>
