<template>
  <SearchBar />
  <div class="container mx-auto border-solid border-grey-400 p-0 lg:border-x">
    <div class="w-full overflow-x-hidden">
      <div class="flex w-full gap-2">
        <div
          v-if="greaterThanMD"
          class="side-modal-content border-r border-solid border-grey-400 md:w-96"
        >
          <SidePanel class="md:w-96" />
        </div>
        <div
          class="grow overflow-x-auto"
          :class="greaterThanMD && 'side-modal-content'"
        >
          <MagicHelperError />
          <MagicHelperMain />
          <SearchInstantResults />
          <ShoppingGuide v-if="showShoppingGuide" />
          <SearchResults />
        </div>
      </div>
    </div>
    <SearchActions />
    <Transition name="fade" duration="1000">
      <div
        v-if="refineLoading"
        class="fixed inset-0 z-50 animate-pulse bg-black/25 transition-all duration-1000"
      ></div>
    </Transition>
  </div>
</template>

<script>
import logger from "@/services/logger";
import { moveToNewSearch } from "@/store/utils/hybrid";
import resolveConfig from "tailwindcss/resolveConfig";
import { mapActions, mapMutations, mapState } from "vuex";
import tailwindConfig from "../../../tailwind.config";
import TrackingService from "../../services/TrackingService";
import MagicHelperError from "./helper/MagicHelperError.vue";
import MagicHelperMain from "./helper/MagicHelperMain.vue";
import SearchBar from "./search-bar/SearchBar.vue";
import SearchActions from "./SearchActions.vue";
import SearchInstantResults from "./SearchInstantResults.vue";
import ShoppingGuide from "./ShoppingGuide.vue";
import SearchResults from "./SearchResults.vue";
import SidePanel from "./SidePanel.vue";
import {
  isContextualEntry,
  mapSearchPageRouteQueryParams,
} from "./utils/search-entry";
import { filterEntryQueryParams } from "@/utils/entry-point";

export default {
  name: "SearchPage",
  components: {
    SearchActions,
    SearchBar,
    SearchResults,
    MagicHelperMain,
    MagicHelperError,
    SearchInstantResults,
    ShoppingGuide,
    SidePanel,
  },
  data() {
    return { showShoppingGuide: process.env.VUE_APP_STAGE !== "production" };
  },
  computed: {
    ...mapState("search", ["loading", "queryText", "queryTags", "queryImage"]),
    ...mapState("hybridActions", ["refineLoading"]),
    ...mapState({
      screenWidth: (state) => state.windowSize.windowWidth,
    }),
    fullConfig() {
      return resolveConfig(tailwindConfig);
    },
    greaterThanMD() {
      return this.screenWidth >= parseInt(this.fullConfig.theme.screens.md);
    },
  },
  watch: {
    $route() {
      this._syncFromRoute();
    },
  },
  mounted() {
    TrackingService.trackPage(this.$EVENTS?.HYBRID?.MAGIC_LOADED);
    this._syncFromRoute();
  },
  methods: {
    ...mapMutations("search", [
      "SET_QUERY_TEXT",
      "SET_QUERY_TAGS",
      "SET_QUERY_IMAGE",
    ]),
    ...mapActions("search", ["search", "reset"]),
    _syncFromRoute() {
      const q = this.$route.query;
      const isContextual = isContextualEntry(q);

      if (isContextual) {
        this._handleContextualEntry(q);
      } else {
        this._handleSearch(q);
      }
    },
    _handleSearch(params) {
      const q = mapSearchPageRouteQueryParams(params);
      const { queryText, queryTags, queryImage } = q;

      this.SET_QUERY_TEXT(queryText);
      this.SET_QUERY_TAGS(queryTags);
      this.SET_QUERY_IMAGE(queryImage);

      this.search(q);
    },
    _handleContextualEntry(params) {
      // eslint-disable-next-line no-unused-vars
      const { query, exp, st, rawData, ...rest } = params;

      const filteredParams = filterEntryQueryParams(rest);
      const tags = Object.entries(filteredParams)
        .filter(([key]) => !key.startsWith("_"))
        .map(([key, value]) => `${key}: ${value}`);

      this._handleContextualEntryTracking(params);

      logger.log("_handleContextualEntry", { query, tags, rawData });

      moveToNewSearch({
        newQuery: query,
        newTags: tags ?? [],
        encoded_filters: rawData,
      });
    },
    _handleContextualEntryTracking(params) {
      logger.log("_handleContextualEntryTracking", params);
      // TODO track
    },
  },
};
</script>
