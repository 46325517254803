import { convertKeysToSnakeCase } from "@/utils/object";
import { stream } from "@/utils/streaming";
import axios from "axios";

const BASE_PATH = `${process.env.VUE_APP_API_URL ?? ""}/api/v2`;

const cancelable = (url) => ({
  cancelable: true,
  cancelableNamespace: "hybrid",
  cancelableKey: url,
});

export async function getInstant(params) {
  const p = new URLSearchParams();
  const { queryText, encoded_filters } = params;
  if (queryText) p.append("q", queryText);
  if (encoded_filters) p.append("encoded_filters", encoded_filters);

  const response = await axios.get(
    `${BASE_PATH}/search/instant?${p.toString()}`,
    cancelable("instant"),
  );

  return response.data;
}

export async function postSearchHybridStream(params, onMessage) {
  const p = convertKeysToSnakeCase(params);
  const body = JSON.stringify(p);

  const url = `${BASE_PATH}/search`;
  await stream(
    url,
    { method: "POST", body, ...cancelable("search") },
    onMessage,
  );
}

export async function postGroupsByQuery(params) {
  const p = convertKeysToSnakeCase(params);
  const url = `${BASE_PATH}/search/query_groups`;

  const response = await axios.post(url, p, cancelable("query_groups"));
  return response.data;
}

export async function postMagicHelper(
  { hybrid_search_id, ...rest },
  forceCancelable = true,
) {
  validateId(hybrid_search_id);

  const url = `${BASE_PATH}/search/${hybrid_search_id}/magic_helper`;

  const response = await axios.post(
    url,
    rest,
    forceCancelable ? cancelable("magic_helper") : undefined,
  );
  return response.data;
}

export async function postMagicCompare(
  { hybrid_search_id, ...rest },
  onMessage,
) {
  validateId(hybrid_search_id);

  const body = JSON.stringify(rest);
  const url = `${BASE_PATH}/search/${hybrid_search_id}/compare`;

  await stream(
    url,
    { method: "POST", body, ...cancelable("compare") },
    onMessage,
  );
}

export async function postMagicSuggestions({ hybrid_search_id, ...rest }) {
  validateId(hybrid_search_id);

  const url = `${BASE_PATH}/search/${hybrid_search_id}/suggestions`;

  const response = await axios.post(url, rest, cancelable("suggestions"));
  return response.data;
}

export async function postRefineHybridSearch({ hybrid_search_id, ...rest }) {
  validateId(hybrid_search_id);

  const url = `${BASE_PATH}/search/${hybrid_search_id}/refine`;

  const response = await axios.post(url, rest, cancelable("refine"));
  return response.data;
}

export async function getProductTags({ hybrid_search_id, language }) {
  validateId(hybrid_search_id);

  const url = `${BASE_PATH}/search/${hybrid_search_id}/tags?language=${language || "PL"}`;

  const response = await axios.get(url, cancelable("tags"));
  return response.data;
}

export async function getShoppingGuideSummary(hybrid_search_id) {
  validateId(hybrid_search_id);
  const url = `${BASE_PATH}/search/${hybrid_search_id}/shopping_guide_entry`;

  const response = await axios.post(
    url,
    {},
    cancelable("shopping_guide_summary"),
  );
  return response.data;
}

export async function getShoppingGuideData(hybrid_search_id) {
  validateId(hybrid_search_id);
  const url = `${BASE_PATH}/search/${hybrid_search_id}/shopping_guide`;

  const response = await axios.post(url, {}, cancelable("shopping_guide"));
  return response.data;
}

function validateId(id) {
  if (!id) {
    throw new Error("id is required");
  }
}
