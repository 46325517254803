<template>
  <div class="flex w-full flex-col">
    <h3 class="mb-4 text-lg font-semibold">
      {{ $t("magic_search_two_point.modal.popular_categories") }}
    </h3>

    <div class="flex gap-2 overflow-x-auto pb-4">
      <BaseButton
        v-for="category in shoppingGuideData.categories"
        :key="category.title"
        variant="tertiary"
        :show-full-text="true"
        @click="makeSearch(category.search_query)"
      >
        {{ category.title }}
        <SearchIcon class="size-4" />
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SearchIcon from "@/components/ui/icons/SearchIcon.vue";
import BaseButton from "@/components/ui/BaseButton.vue";

export default {
  name: "PopularCategories",
  components: { SearchIcon, BaseButton },
  computed: {
    ...mapGetters("guideActions", ["shoppingGuideData"]),
  },
  methods: { ...mapActions("guideActions", ["makeSearch"]) },
};
</script>
