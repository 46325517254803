import DOMPurify from "dompurify";

export function sentenceCase(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function camelToSnake(camelCaseString) {
  return camelCaseString.replace(
    /[A-Z]/g,
    (letter) => `_${letter.toLowerCase()}`,
  );
}

export function snakeCaseToCamel(s) {
  return s
    .split("_")
    .map((a) => sentenceCase(a))
    .join("");
}

export function sanitizeHTML(str) {
  return DOMPurify.sanitize(str);
}

export function sanitizeMessage(content) {
  // Convert <br> and <div> tags to newlines
  let sanitized = content
    .replace(/<br\s*\/?>/gi, "\n")
    .replace(/<div>/gi, "\n")
    .replace(/<\/div>/gi, "");

  // Remove all HTML tags
  sanitized = sanitized.replace(/<[^>]*>/g, "");

  // Remove continuous newlines (more than 2)
  sanitized = sanitized.replace(/\n{3,}/g, "\n\n");

  // Trim leading and trailing whitespace
  sanitized = sanitized.trim();
  return sanitized;
}

export function levenshteinDistance(s1, s2) {
  const m = s1.length,
    n = s2.length;
  const dp = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0));

  for (let i = 0; i <= m; i++) {
    for (let j = 0; j <= n; j++) {
      if (i === 0) {
        dp[i][j] = j;
      } else if (j === 0) {
        dp[i][j] = i;
      } else if (s1[i - 1] === s2[j - 1]) {
        dp[i][j] = dp[i - 1][j - 1];
      } else {
        dp[i][j] = 1 + Math.min(dp[i - 1][j], dp[i][j - 1], dp[i - 1][j - 1]);
      }
    }
  }
  return dp[m][n];
}

export function similarityScore(s1, s2) {
  const maxLen = Math.max(s1.length, s2.length);
  if (maxLen === 0) return 1.0;
  return 1 - levenshteinDistance(s1, s2) / maxLen;
}
