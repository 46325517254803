import { isAbortedError } from "@/utils/error";
import logger from "@/services/logger";
import { moveToNewSearch } from "@/store/utils/hybrid";
import { i18n } from "@/locale/i18n";
import {
  getShoppingGuideData,
  getShoppingGuideSummary,
} from "@/services/SearchServiceV2";

const defaultState = () => ({
  shoppingGuideModalOpen: false,
  shoppingGuideData: null,
  shoppingGuideSummary: null,
  shoppingGuideDataError: null,
  shoppingGuideDataLoading: false,
});

const state = defaultState();

const getters = {
  shoppingGuideModalOpen: (state) => state.shoppingGuideModalOpen,
  shoppingGuideData: (state) => state.shoppingGuideData,
  shoppingGuideSummary: (state) => state.shoppingGuideSummary,
  shoppingGuideDataError: (state) => state.shoppingGuideDataError,
  shoppingGuideDataLoading: (state) => state.shoppingGuideDataLoading,
};

const actions = {
  async fetchShoppingGuideData({ commit }, hybrid_search_id) {
    if (!hybrid_search_id) {
      commit("SET_SHOPPING_GUIDE_DATA", null);
      return;
    }

    try {
      commit("SET_SHOPPING_GUIDE_LOADING", true);
      commit("SET_SHOPPING_GUIDE_ERROR", null);
      const response = await getShoppingGuideData(hybrid_search_id);
      if (response.error) {
        logger.error("Shopping guide error:", response.error);
        commit("SET_SHOPPING_GUIDE_DATA", null);
        commit(
          "SET_SHOPPING_GUIDE_ERROR",
          i18n.global.t("search.helper.generic_error"),
        );
      } else {
        commit("SET_SHOPPING_GUIDE_DATA", response);
      }
      commit("SET_SHOPPING_GUIDE_LOADING", false);
    } catch (e) {
      if (isAbortedError(e)) return;

      logger.error("Shopping guide error:", e);
      commit("SET_SHOPPING_GUIDE_DATA", null);
      commit(
        "SET_SHOPPING_GUIDE_ERROR",
        i18n.global.t("search.helper.generic_error"),
      );
    }
  },
  async fetchShoppingGuideSummary({ commit, rootGetters }) {
    const hybrid_search_id = rootGetters["search/searchId"];

    if (!hybrid_search_id) {
      commit("SET_SHOPPING_GUIDE_SUMMARY", null);
      return;
    }
    try {
      const response = await getShoppingGuideSummary(hybrid_search_id);
      commit("SET_SHOPPING_GUIDE_SUMMARY", response);
    } catch (e) {
      if (isAbortedError(e)) return;

      logger.error("Shopping summary error:", e);
      commit("SET_SHOPPING_GUIDE_SUMMARY", null);
    }
  },
  makeSearch({ commit, state }, query) {
    commit("SET_SHOPPING_GUIDE_MODAL_OPEN", false);
    commit("SET_SHOPPING_GUIDE_DATA", null);
    commit("SET_SHOPPING_GUIDE_SUMMARY", null);
    moveToNewSearch({
      newQuery: query,
      newTags: null,
      newImage: null,
      origin_hybrid_search_id: state.hybridSearch?.id,
    });
  },
};

const mutations = {
  SET_SHOPPING_GUIDE_MODAL_OPEN: (state, value) =>
    (state.shoppingGuideModalOpen = value),

  SET_SHOPPING_GUIDE_DATA(state, response) {
    state.shoppingGuideData = response;
  },

  SET_SHOPPING_GUIDE_ERROR: (state, error) => {
    state.shoppingGuideDataError = error;
  },

  SET_SHOPPING_GUIDE_SUMMARY(state, response) {
    state.shoppingGuideSummary = response;
  },

  SET_SHOPPING_GUIDE_LOADING: (state, loading) => {
    state.shoppingGuideDataLoading = loading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
