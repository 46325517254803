<template>
  <div>
    <h3 class="mb-4 text-lg font-semibold">
      {{ shoppingGuideData.types.title }}
    </h3>
    <div class="grid grid-cols-2 gap-4">
      <div
        v-for="type in shoppingGuideData.types.types"
        :key="type.title"
        class="rounded-xl border bg-white p-4 shadow"
      >
        <div class="flex items-start justify-between gap-2">
          <div class="grow">
            <h4 class="mb-2 font-medium">{{ type.title }}</h4>
            <ul class="w-full space-y-1 text-sm">
              <li
                v-for="typeGood in type.good"
                :key="'good_' + typeGood"
                class="flex gap-2 text-gray-600"
              >
                <CheckIcon class="size-4 shrink-0 text-teal-600" />
                <span class="grow">{{ typeGood }}</span>
              </li>
              <li
                v-for="typeBad in type.bad"
                :key="'bad_' + typeBad"
                class="flex gap-2 text-gray-600"
              >
                <CrossIcon class="size-4 shrink-0 text-red-500" />
                <span class="grow">{{ typeBad }}</span>
              </li>
            </ul>
          </div>
          <BaseButton
            class="-mt-1"
            variant="tertiary"
            @click="makeSearch(type.search_query)"
          >
            <SearchIcon class="size-4" />
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CheckIcon from "@/components/ui/icons/CheckIcon.vue";
import CrossIcon from "@/components/ui/icons/CrossIcon.vue";
import SearchIcon from "@/components/ui/icons/SearchIcon.vue";
import BaseButton from "@/components/ui/BaseButton.vue";

export default {
  name: "ShoppingTypes",
  components: { CheckIcon, CrossIcon, SearchIcon, BaseButton },
  computed: {
    ...mapGetters("guideActions", ["shoppingGuideData"]),
  },
  methods: { ...mapActions("guideActions", ["makeSearch"]) },
};
</script>
