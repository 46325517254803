<template>
  <Transition name="slide-left" duration="300">
    <div
      v-if="shoppingGuideDataError"
      class="flex flex-col items-start text-sm transition-all duration-300 ease-in-out"
    >
      <MagicHelperBubble variant="error">
        <Markdown :text="shoppingGuideDataError" />

        <div class="mt-3 flex w-full items-center">
          <BaseButton class="ml-auto" variant="error" @click="onRetry">
            {{ $t("search.helper.main_error_retry") }}
          </BaseButton>
        </div>
      </MagicHelperBubble>
    </div>
  </Transition>
</template>

<script>
import BaseButton from "@/components/ui/BaseButton.vue";
import Markdown from "@/components/ui/Markdown.vue";
import { mapActions, mapGetters } from "vuex";
import MagicHelperBubble from "../helper/MagicHelperBubble.vue";

export default {
  name: "ShoppingGuideError",
  components: {
    Markdown,
    MagicHelperBubble,
    BaseButton,
  },
  computed: {
    ...mapGetters("guideActions", ["shoppingGuideDataError"]),
    ...mapGetters("search", ["searchId"]),
  },
  methods: {
    ...mapActions("guideActions", ["fetchShoppingGuideData"]),
    onRetry() {
      this.fetchShoppingGuideData(this.searchId);
    },
  },
};
</script>
