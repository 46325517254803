<template>
  <div class="rounded-xl border border-amber-200 p-6 shadow">
    <div class="mb-4 flex items-center gap-2">
      <WarningIcon class="size-5 text-amber-500" />
      <h3 class="text-lg font-semibold">
        {{ $t("magic_search_two_point.modal.smart_shopping_tips") }}
      </h3>
    </div>
    <div class="grid grid-cols-1 gap-4">
      <div
        v-for="warning in shoppingGuideData.warnings"
        :key="warning.title"
        class="space-y-1"
      >
        <h4 class="text-sm font-medium">{{ warning.title }}</h4>
        <div class="text-sm text-gray-600">
          {{ warning.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WarningIcon from "@/components/ui/icons/WarningIcon.vue";

export default {
  name: "ShoppingWarnings",
  components: { WarningIcon },
  computed: {
    ...mapGetters("guideActions", ["shoppingGuideData"]),
  },
  methods: {},
};
</script>
