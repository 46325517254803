<template>
  <div
    v-if="shoppingGuideData"
    class="mx-auto flex w-full max-w-6xl flex-col space-y-6 p-4"
  >
    <div class="flex flex-col gap-4">
      <h1 class="text-3xl font-bold text-gray-800 md:text-4xl">
        <!-- {{ $t("magic_search_two_point.modal.shopping_guide_heading")
        }}<span
          class="bg-gradient-to-r from-teal-700 to-teal-500 bg-clip-text text-transparent"
        >
          electric kettle</span
        > -->
        {{ shoppingGuideData.title }}
      </h1>
    </div>
    <PopularCategories />
    <SmartShoppingTips />
    <ShoppingWarnings />
    <FeaturedProduct />
    <ShoppingTypes />
    <MarketPrices />
    <ShoppingPicks />
    <Personas />
    <div class="pb-2"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PopularCategories from "./shoppingGuide/PopularCategories.vue";
import SmartShoppingTips from "./shoppingGuide/SmartShoppingTips.vue";
import ShoppingWarnings from "./shoppingGuide/ShoppingWarnings.vue";
import FeaturedProduct from "./shoppingGuide/FeaturedProduct.vue";
import ShoppingTypes from "./shoppingGuide/ShoppingTypes.vue";
import MarketPrices from "./shoppingGuide/MarketPrices.vue";
import ShoppingPicks from "./shoppingGuide/ShoppingPicks.vue";
import Personas from "./shoppingGuide/Personas.vue";

export default {
  name: "ShoppingGuideContent",
  components: {
    PopularCategories,
    SmartShoppingTips,
    ShoppingWarnings,
    FeaturedProduct,
    ShoppingTypes,
    MarketPrices,
    ShoppingPicks,
    Personas,
  },
  computed: {
    ...mapGetters("guideActions", ["shoppingGuideData"]),
  },
  methods: {},
};
</script>
