<template>
  <div
    class="rounded-xl border bg-white bg-gradient-to-br from-gray-50 to-gray-100 p-6 shadow"
  >
    <h3 class="mb-2 text-xl font-semibold">
      {{ $t("magic_search_two_point.modal.market_prices") }}
    </h3>
    <p class="mb-4 text-sm text-gray-600">
      {{ $t("magic_search_two_point.modal.based_on_market_analysis") }}
    </p>
    <div class="relative h-20">
      <div class="absolute inset-x-0 top-0 h-4 rounded-full bg-gray-200">
        <div
          class="h-full rounded-full bg-gradient-to-r from-teal-200 via-teal-400 to-teal-600 opacity-20"
        ></div>
        <div
          class="absolute left-1/3 top-0 h-full w-1/3 rounded-full bg-teal-500 opacity-40"
        ></div>
      </div>
      <div class="absolute top-6 flex w-full justify-between text-sm">
        <div>
          <div class="text-gray-900">
            {{ shoppingGuideData.price_analysis.budget }} zł
          </div>
          <div class="text-gray-600">
            {{ $t("magic_search_two_point.modal.market_budget") }}
          </div>
        </div>
        <div>
          <div class="font-medium text-teal-600">
            {{ shoppingGuideData.price_analysis.sweet_spot }} zł
          </div>
          <div class="font-medium text-teal-700">
            {{ $t("magic_search_two_point.modal.market_sweet_spot") }}
          </div>
        </div>
        <div>
          <div class="text-gray-900">
            {{ shoppingGuideData.price_analysis.premium }} zł
          </div>
          <div class="text-gray-600">
            {{ $t("magic_search_two_point.modal.market_premium") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MarketPrices",
  components: {},
  computed: {
    ...mapGetters("guideActions", ["shoppingGuideData"]),
  },
  methods: {},
};
</script>
