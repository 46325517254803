<template>
  <div class="rounded-xl border p-4 shadow">
    <h3 class="mb-4 text-lg font-semibold">
      {{ $t("magic_search_two_point.modal.smart_shopping_tips") }}
    </h3>
    <div class="grid grid-cols-1 gap-4">
      <div
        v-for="tip in shoppingGuideData.tips"
        :key="tip.title"
        class="flex items-start gap-3"
      >
        <div class="mt-1">
          <LuicideIcon
            :name="tip.icon"
            class="size-5 text-teal-600"
            :stroke-width="2"
          />
        </div>
        <div class="flex flex-col">
          <h4 class="text-sm font-medium">{{ tip.title }}</h4>
          <div class="text-sm text-gray-600">
            {{ tip.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LuicideIcon from "@/components/ui/LuicideIcon.vue";

export default {
  name: "SmartShoppingTips",
  components: { LuicideIcon },
  computed: {
    ...mapGetters("guideActions", ["shoppingGuideData"]),
  },
  methods: {},
};
</script>
