<template>
  <RouterLink v-if="href.startsWith('/')" :to="href">
    <slot></slot>
  </RouterLink>
  <a v-else :href="href">
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {},
};
</script>
